<template>
  <v-dialog
    v-model="modalState"
    transition="scale-transition"
    max-width="400"
    persistent
  >
    <template v-slot:default="modalState">
      <v-card class="pb-2">
        <v-container class="text-h5 text-center">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center mb-5 rounded-lg">
                <div
                  class="card-icon-container red lighten-4 d-flex  align-center justify-center rounded-circle pa-5 mx-auto"
                >
                  <v-icon color="red" large
                    >mdi-content-save-alert-outline</v-icon
                  >
                </div>
              </div>
            </v-col>
          </v-row>

          <p class="mb-0 mx-auto">¿Está seguro que desea salir?</p>
        </v-container>

        <v-card-text>
          <p class="text-body-1 text-center">
            Los datos agregados ó modificados que no se hayan guardado se
            perderán.
          </p>
        </v-card-text>

        <v-card-actions class="action-buttons-container">
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-4"
            text
            @click="modalState.value = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="red darken-1 white--text update-btn"
            @click="
              resetParentForm(), (modalState.value = false), confirmData()
            "
          >
            Sí, salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
  export default {
    name: "ConfirmationModal",
    data() {
      return {
        modalState: false,
      };
    },
    methods: {
      toggleModal() {
        this.modalState = !this.modalState;
      },
      confirmData() {
        this.$emit("confirmData");
      },
      resetParentForm() {
        this.$emit("resetForm");
      },
    },
  };
</script>

<style>
  .card-icon-container {
    aspect-ratio: 1/1;
    height: 100%;
    min-height: 100px !important;
  }
  @media (max-width: 350px) {
    .action-buttons-container {
      flex-direction: column !important;
    }
    .update-btn {
      margin-bottom: 1em !important;
    }
    .fallback-alert {
      display: none !important;
    }
  }
</style>
