<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`blue lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="mt-5 px-5 px-sm-15">
        <v-row>
          <v-col>
            <p class="display-2 font-weight-light">
              Creación de solicitud para registro de asistencia diferida
            </p>
            <p class="text-muted text-body-1">
              Puede crear una solicitud para registrar la asistencia de una
              clase pasada
              <span class="font-weight-bold deep-orange--text"
                >el mismo día que ocurrió</span
              >.
            </p>
          </v-col>
        </v-row>
      </div>

      <div class="px-sm-15 px-5">
        <v-row>
          <v-col cols="12" md="7" lg="8" xl="8" class="mt-11">
            <v-stepper v-model="stepperActiveStep" class="rounded-lg">
              <v-stepper-header>
                <!-- Stepper steps on top -->
                <v-stepper-step :complete="stepperActiveStep > 1" step="1">
                  Registro de motivo
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="stepperActiveStep > 2" step="2">
                  Selección de estudiantes
                </v-stepper-step>
              </v-stepper-header>

              <!-- Stepper steps content -->

              <v-stepper-items>
                <v-stepper-content step="1">
                  <!-- date selector and motive -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-dialog
                          ref="arrivalDayPickerModalState"
                          v-model="arrivalDayPickerModalState"
                          :return-value.sync="deferredAttendanceRequest.date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <form-group
                              name="día a registrar asistencia diferida"
                              :validator="$v.deferredAttendanceRequest.date"
                            >
                              <v-text-field
                                class="mt-2"
                                v-model="deferredAttendanceRequest.date"
                                label="Día a registrar asistencia diferida"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                rounded
                                filled
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </form-group>
                          </template>

                          <v-date-picker
                            :max="today"
                            :min="today"
                            :allowed-dates="filterAllowedDates"
                            v-model="deferredAttendanceRequest.date"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="arrivalDayPickerModalState = false"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.arrivalDayPickerModalState.save(
                                  deferredAttendanceRequest.date
                                )
                              "
                            >
                              Registrar
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12" md="6">
                        <form-group
                          name="razón"
                          :validator="
                            $v.deferredAttendanceRequest.request_reason_id
                          "
                        >
                          <v-select
                            class="mt-2"
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            label="Razón de la solicitud"
                            placeholder="Razón de la solicitud"
                            :items="requestReasons.data"
                            :loading="requestReasons.isLoading"
                            :disabled="!requestReasons.data.length"
                            item-text="name"
                            item-value="id"
                            required
                            filled
                            rounded
                            hide-details
                            dense
                            v-model="
                              deferredAttendanceRequest.request_reason_id
                            "
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip
                                color="primary"
                                class="ml-n1"
                                small
                                v-if="index === 0"
                              >
                                <span>{{ item.name }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                        </form-group>
                      </v-col>
                    </v-row>
                    <v-scroll-x-reverse-transition>
                      <p
                        v-if="dayHasBeenSelected"
                        class="text-secondary font-weight-bold mt-n8 ml-7"
                      >
                        {{ selectedDayFormatted }}
                      </p>
                    </v-scroll-x-reverse-transition>

                    <v-row class="mt-n2">
                      <v-col cols="12">
                        <form-group
                          name="Motivo"
                          :validator="
                            $v.deferredAttendanceRequest.justification
                          "
                        >
                          <v-textarea
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            counter
                            filled
                            clearable
                            rounded
                            auto-grow
                            rows="2"
                            label="Motivo"
                            v-model.trim="
                              deferredAttendanceRequest.justification
                            "
                            @paste="
                              handlePaste(
                                $event,
                                'deferredAttendanceRequest.justification'
                              )
                            "
                            @keyup.enter="handleKeyUpMotive()"
                          ></v-textarea>
                        </form-group>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- First step actions -->
                  <div class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      @click="setAndValidateSelectedDateAndMotive()"
                    >
                      Continuar
                    </v-btn>
                  </div>
                </v-stepper-content>
                <!-- ----------------------------------------------------------------------- -->
                <v-stepper-content step="2">
                  <!-- second step actions -->
                  <div
                    class="pa-5 d-flex justify-center"
                    v-if="fetchingScheduleStudents"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                  <div v-else>
                    <v-data-table
                      :search.sync="studentSearch"
                      :headers="studentsFromScheduleHeaders"
                      :items="formattedStudentClassList"
                      :items-per-page="-1"
                      item-key="full_name"
                      sort-by="full_name"
                      :group-by="tableManage"
                      show-group-by
                      class="elevation-0"
                      hide-default-footer
                      :custom-filter="customFilter"
                      mobile-breakpoint="1360"
                    >
                      <template v-slot:top>
                        <v-row class="mx-md-4">
                          <!-- begin::search text field -->
                          <v-col class="pb-0" cols="12">
                            <v-text-field
                              filled
                              rounded
                              prepend-icon=""
                              prepend-inner-icon="mdi-magnify"
                              placeholder="Buscar estudiante"
                              v-model="studentSearch"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-space-between align-center flex-column flex-lg-row mt-0 mb-10"
                          >
                            <div
                              class="d-flex flex-column justify-start align-center flex-md-row justify-md-start align-md-center"
                            >
                              <v-fade-transition :leave-absolute="true">
                                <v-avatar
                                  v-if="
                                    totalStudentStats()
                                      .notValidAttendaceStudents > 0
                                  "
                                  color="red lighten-4"
                                  size="80"
                                >
                                  <v-icon dark large color="red">
                                    mdi-account-alert-outline
                                  </v-icon>
                                </v-avatar>
                              </v-fade-transition>

                              <v-fade-transition :hide-on-leave="true">
                                <v-avatar
                                  v-if="
                                    totalStudentStats()
                                      .notValidAttendaceStudents < 1
                                  "
                                  color="green lighten-4"
                                  size="80"
                                >
                                  <v-icon dark large color="success">
                                    mdi-check-circle-outline
                                  </v-icon>
                                </v-avatar>
                              </v-fade-transition>

                              <div
                                class="d-flex flex-column justify-md-center align-md-start pt-2 pt-md-0 pl-md-3"
                              >
                                <div
                                  class="text-subtitle-1 font-weight-bold text-center text-md-left"
                                >
                                  Resumen de asistencia
                                </div>
                                <div
                                  class="text-body-1 font-weight-medium blue-grey--text text--darken-3 text-center text-md-left"
                                >
                                  <span class="font-weight-bold">{{
                                    totalStudentStats().totalStudents
                                  }}</span>
                                  estudiantes en total
                                </div>

                                <div
                                  v-if="
                                    totalStudentStats()
                                      .notValidAttendaceStudents > 0
                                  "
                                  class="text-body-1 font-weight-medium blue-grey--text text--darken-3 text-center text-md-left"
                                >
                                  <span class="font-weight-bold">{{
                                    totalStudentStats()
                                      .notValidAttendaceStudents
                                  }}</span>
                                  estudiantes sin asistencia
                                </div>

                                <div
                                  v-else
                                  class="text-body-1 font-weight-bold success--text text-center text-md-left"
                                >
                                  ¡Todos los estudiantes cuentan con asistencia!
                                </div>
                              </div>
                            </div>

                            <v-btn
                              v-if="
                                totalStudentStats().notValidAttendaceStudents >
                                1
                              "
                              class="success mt-3 mt-lg-0"
                              @click="
                                openConfirmationModalForStudentStateUpdate()
                              "
                            >
                              <span class="font-weight-medium"
                                >Marcar todos como
                                <span class="font-weight-bold"
                                  >"Presente"</span
                                ></span
                              >
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <!-- ------------------------- -->
                      <template
                        v-slot:[`group.header`]="{
                          group,
                          headers,
                          toggle,
                          isOpen,
                        }"
                      >
                        <td :colspan="headers.length">
                          <v-btn
                            @click="toggle"
                            small
                            icon
                            :ref="group"
                            :data-open="isOpen"
                          >
                            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                            <v-icon v-else>mdi-chevron-down</v-icon>
                          </v-btn>
                          <v-icon dense dark left color="blue"
                            >mdi-account-group</v-icon
                          >
                          <span
                            class="blue-grey--text text--darken-3 font-weight-bold text-subtitle-1"
                            >{{ group }}</span
                          >
                        </td>
                      </template>
                      <!-- ------------------------- -->
                      <template v-slot:[`item.full_name`]="{ item }">
                        <div class="d-flex py-2">
                          <div>
                            <v-avatar size="50">
                              <img :src="item.photo" :alt="item.full_name" />
                            </v-avatar>
                          </div>
                          <div class="ml-sm-2 align-center">
                            <p class="mb-1 text-h6">
                              {{ item.code }}
                              <v-chip
                                class="ml-2"
                                v-if="item.is_justified"
                                small
                                :color="`primary lighten-5`"
                                ><span class="font-weight-bold primary--text"
                                  >Justificado
                                  <v-icon x-small
                                    >mdi-check-circle</v-icon
                                  ></span
                                ></v-chip
                              >
                            </p>

                            <p class="mb-0">{{ item.full_name }}</p>
                          </div>
                        </div>
                      </template>
                      <template
                        v-slot:[`item.old_attendance_status_id`]="{ item }"
                      >
                        <v-chip
                          :color="`${
                            setAttendanceStatusTheme(
                              item.old_attendance_status_id
                            ).color
                          } lighten-5`"
                          small
                        >
                          <span
                            :class="`${
                              setAttendanceStatusTheme(
                                item.old_attendance_status_id
                              ).color
                            }--text font-weight-bold`"
                            >{{
                              formattedAttendanceStatus(
                                item.old_attendance_status_id
                              ).name
                            }}</span
                          >
                        </v-chip>
                      </template>
                      <template
                        v-slot:[`item.new_attendance_status_id`]="{ item }"
                      >
                        <v-select
                          :items="attendanceStatusesToChoose"
                          :loading="
                            isLoadingAttendanceStatus ||
                            fetchingScheduleStudents
                          "
                          :disabled="
                            isLoadingAttendanceStatus ||
                            fetchingScheduleStudents ||
                            item.is_justified
                          "
                          item-text="name"
                          item-value="id"
                          v-model="item.new_attendance_status_id"
                          hide-details
                          rounded
                          filled
                          dense
                          :class="`${
                            item.new_attendance_status_id ===
                            PENDING_ATTENDACE_ID
                              ? 'yellow accent-4'
                              : ''
                          }`"
                        >
                          <!-- begin::selected academicGroup item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              class="mb-2 mt-3"
                              :color="`${
                                setAttendanceStatusTheme(item.id).color
                              } lighten-5`"
                              small
                              v-if="index === 0"
                            >
                              <span
                                :class="`${
                                  setAttendanceStatusTheme(item.id).color
                                }--text text--darken-1 font-weight-bold`"
                                >{{
                                  formattedAttendanceStatus(item.id).name
                                }}</span
                              >
                            </v-chip>
                          </template>
                          <!-- end::selected academicGroup item -->
                        </v-select>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="d-flex justify-end mt-6">
                    <v-btn text @click="goBackFromSecondStep()">
                      Regresar
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="ml-sm-2"
                      @click="reviewCreatedRequest()"
                    >
                      Continuar
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>

          <!-- selected schedule details -->
          <v-col cols="12" md="5" lg="4" xl="4">
            <v-card class="elevation-0">
              <p class="text-center text-h5">Horario seleccionado</p>
              <v-row>
                <v-col>
                  <SquaredIconCard
                    :title="`${selectedSchedule.teacher_group?.name}`"
                    :subTitle="`${selectedSchedule.teacher_group?.grade_information}`"
                    icon="book-account-outline"
                    colorTheme="indigo"
                  ></SquaredIconCard>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6">
                  <SquaredIconCard
                    title="Horario"
                    :subTitle="`${selectedSchedule.class_time?.start_time} - ${selectedSchedule.class_time?.end_time}`"
                    icon="clock-time-four-outline"
                    colorTheme="blue"
                  ></SquaredIconCard>
                </v-col>
                <v-col cols="12" lg="6">
                  <SquaredIconCard
                    title="Se imparte"
                    :subTitle="`${selectedSchedule.day?.name}`"
                    icon="clock-time-four-outline"
                    colorTheme="blue"
                  ></SquaredIconCard>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6">
                  <SquaredIconCard
                    title="Periodo"
                    :subTitle="`${selectedSchedule?.start_date} - ${selectedSchedule?.end_date}`"
                    icon="map-marker"
                    colorTheme="green"
                  ></SquaredIconCard>
                </v-col>
                <v-col cols="12" lg="6">
                  <SquaredIconCard
                    title="Local"
                    :subTitle="`${selectedSchedule.local?.name}`"
                    icon="map-marker"
                    colorTheme="green"
                  ></SquaredIconCard>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <ConfirmDeferredAttendanceRequestModal
      ref="MdlConfirmDeferredAttendanceRequest"
      :payload="deferredAttendanceRequest"
      :schedule="selectedSchedule"
      :attendanceStatuses="attendanceStatuses"
      :requestReasons="requestReasons.data"
      @successfullySaved="toggleModal()"
    ></ConfirmDeferredAttendanceRequestModal>
    <HandleAttendanceStateChangeModal
      ref="MdlHandleAttendanceStateChange"
      @action="updateAttendanceStatuses()"
    ></HandleAttendanceStateChangeModal>
    <GoBackFromSecondStepConfirmationModal
      ref="MdlGoBackFromSecondStepConfirmation"
      @action="resetStudentsList(), (stepperActiveStep = 1)"
    ></GoBackFromSecondStepConfirmationModal>
    <NoChangedStatusFoundInformationModal
      ref="MdlNoChangedStatusFoundInformation"
      :alertMessage="notAllowedDiferredRequestCreationMessage"
    ></NoChangedStatusFoundInformationModal>
  </v-dialog>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";
import dayjs from "dayjs";
import IconCard from "@/components/elements/dashboard/IconCard";
import SquaredIconCard from "@/components/elements/dashboard/SquaredIconCard";
import { required } from "vuelidate/lib/validators";
import { withParams } from "vuelidate/lib";
import studentClassRepository from "../../../repositories/studentClassRepository";
import requestReasonRepository from "@/repositories/requestReasonRepository";
import attendanceStatusRepository from "../../../repositories/attendanceStatusRepository";
import ConfirmDeferredAttendanceRequestModal from "@/components/modals/attendance/ConfirmDeferredAttendanceRequestModal.vue";
import GoBackFromSecondStepConfirmationModal from "@/components/modals/attendance/GoBackFromSecondStepConfirmationModal.vue";
import NoChangedStatusFoundInformationModal from "@/components/modals/attendance/NoChangedStatusFoundInformationModal.vue";
import HandleAttendanceStateChangeModal from "@/components/modals/attendance/MarkAllStudentsPresentModal.vue";

export default {
  name: "DeferredAttendanceRequestForm",

  props: {
    selectedSchedule: {
      type: Object,
    },
  },

  components: {
    IconCard,
    DeleteBaseModal,
    SquaredIconCard,
    NoChangedStatusFoundInformationModal,
    ConfirmDeferredAttendanceRequestModal,
    GoBackFromSecondStepConfirmationModal,
    HandleAttendanceStateChangeModal,
  },

  mounted() {
    // this.getCurrentDay();
    this.$store.dispatch(
      GET_CURRENT_PAGE_ACTIONS,
      "Registro de asistencia diferida"
    );
  },

  data() {
    return {
      studentSearch: "",
      filteredStudents: [],
      stepperActiveStep: 1,
      date: null,
      arrivalDayPickerModalState: false,
      dialogActiveState: false,
      currentDay: "",
      notAllowedDiferredRequestCreationMessage: "",
      fetchingScheduleStudents: false,
      attendanceStatusesToChoose: [],
      attendanceStatuses: [],
      formattedStudentClassList: [],
      allStudentsFromSchedule: [],
      REQUEST_CLASIFICATION_ID: 3,
      PENDING_ATTENDACE_ID: 4,
      requestReasons: {
        isLoading: false,
        data: [],
      },

      deferredAttendanceRequest: {
        schedule_id: this.selectedSchedule?.idschedule,
        date: "",
        user_id: this.currentUserPersonalInfo?.id_user,
        justification: "",
        request_reason_id: null,
        student_class: [],
      },
      studentsFromScheduleHeaders: [
        {
          text: "Estudiante",
          value: "full_name",
          groupable: false,
          sortable: false,
        },
        {
          text: "Estado actual",
          value: "old_attendance_status_id",
          groupable: false,
          sortable: false,
        },
        {
          text: "Nuevo estado",
          value: "new_attendance_status_id",
          groupable: false,
          sortable: false,
        },
      ],
      notValidAttendaceStatus: {
        "Pendiente de asistencia": true,
        "Llegada tarde a institución": true,
      },
    };
  },

  methods: {
    goBackFromSecondStep() {
      this.$refs.MdlGoBackFromSecondStepConfirmation.toggleModal();
    },

    openConfirmationModalForStudentStateUpdate() {
      this.$refs.MdlHandleAttendanceStateChange.toggleModal();
    },

    updateAttendanceStatuses() {
      let changedStatuses = 0;
      for (let i = 0; i < this.formattedStudentClassList.length; i++) {
        if (
          this.formattedStudentClassList[i].new_attendance_status_id ===
          this.PENDING_ATTENDACE_ID
        ) {
          this.formattedStudentClassList[i].new_attendance_status_id = 1;
          changedStatuses++;
        }
      }

      if (changedStatuses > 0) {
        this.fireToast({
          icon: "success",
          title: `Se cambió el estado de ${changedStatuses} estudiantes.`,
          timer: 5000,
        });
      } else {
        this.fireToast({
          icon: "success",
          title: `No se encontraron estudiantes con estado de asistencia pendiente.`,
          timer: 7000,
        });
      }
    },

    customFilter(value, search, item) {
      const fullNameMatch = item.full_name
        .toLowerCase()
        .includes(search.toLowerCase());
      const codeMatch = item.code.toString().includes(search.toLowerCase());

      return fullNameMatch || codeMatch;
    },

    resetStudentsList() {
      this.formattedStudentClassList = [];
      this.allStudentsFromSchedule = [];
      this.deferredAttendanceRequest.student_class = [];
    },

    getRequestReasons() {
      this.requestReasons.isLoading = true;
      requestReasonRepository
        .getRequestReasonsByRequestClasificationId(
          this.REQUEST_CLASIFICATION_ID
        )
        .then(({ data }) => {
          this.requestReasons.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestReasons.isLoading = false;
        });
    },

    openUpdateObservationModal(observation) {
      this.$emit("openUpdateObservationModal", observation);
    },

    toggleModal() {
      if (!this.dialogActiveState) {
        this.resetStudentsList();
        this.stepperActiveStep = 1;

        this.deferredAttendanceRequest = {
          schedule_id: null,
          date: "",
          user_id: null,
          justification: "",
          request_reason_id: null,
          student_class: [],
        };
        this.$v.$reset();
      }

      this.dialogActiveState = !this.dialogActiveState;
    },

    getAttendanceStatus() {
      this.isLoadingAttendanceStatus = true;
      attendanceStatusRepository
        .getAllAttendanceStatus()
        .then((data) => {
          this.attendanceStatuses = [...data.data.data];
          let statuses = [...data.data.data];

          for (let itemName in this.notValidAttendaceStatus) {
            if (this.notValidAttendaceStatus[itemName]) {
              let indexToRemove = statuses.findIndex(
                (obj) => obj.name === itemName
              );
              if (indexToRemove !== -1) {
                statuses.splice(indexToRemove, 1);
              }
            }
          }

          this.attendanceStatusesToChoose = statuses;
        })
        .catch()
        .finally(() => {
          this.isLoadingAttendanceStatus = false;
        });
    },

    formattedAttendanceStatus(id) {
      let foundStatus = this.attendanceStatuses.find((status) => {
        return status.id === id;
      });
      return foundStatus;
    },

    CheckAllStudentsHaveAValidAttendanceStatus(studentsList) {
      for (let index = 0; index < studentsList.length; index++) {
        const student = studentsList[index];

        // Get the formatted attendance status name using your method
        const formattedStatus = this.formattedAttendanceStatus(
          student.new_attendance_status_id
        );

        // Check if the formatted status is not allowed
        if (this.notValidAttendaceStatus[formattedStatus.name]) {
          return false; // Return false if not allowed value is found
        }
      }

      return true; // Return true if all students have valid attendance status
    },

    reviewCreatedRequest() {
      //Filter unchanged students

      let allStudentsHaveSomeValidTypeOfAttendance =
        this.CheckAllStudentsHaveAValidAttendanceStatus(
          this.formattedStudentClassList
        );

      let filteredArray = this.formattedStudentClassList.filter(
        (obj) => obj.new_attendance_status_id !== obj.old_attendance_status_id
      );

      if (
        filteredArray.length > 0 &&
        allStudentsHaveSomeValidTypeOfAttendance
      ) {
        this.deferredAttendanceRequest.student_class = filteredArray;

        this.deferredAttendanceRequest.user_id =
          this.currentUserPersonalInfo?.id_user;

        this.deferredAttendanceRequest.schedule_id =
          this.selectedSchedule.idschedule;

        this.$refs.MdlConfirmDeferredAttendanceRequest.toggleModal();
        return;
      }

      if (filteredArray.length < 1) {
        this.notAllowedDiferredRequestCreationMessage =
          "No se encontraron cambios en la lista de asistencia";
        this.$refs.MdlNoChangedStatusFoundInformation.toggleModal();
        return;
      }

      if (!allStudentsHaveSomeValidTypeOfAttendance) {
        this.notAllowedDiferredRequestCreationMessage =
          "Aún hay estudiantes sin registro de asistencia";
        this.$refs.MdlNoChangedStatusFoundInformation.toggleModal();
        return;
      }
    },

    setAndValidateSelectedDateAndMotive() {
      //validate selected day and motive
      this.$v.deferredAttendanceRequest.$touch();

      if (this.$v.deferredAttendanceRequest.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      //if all good,
      //load students by selected schedule
      this.stepperActiveStep = 2;
      this.getStudentsFromSelectedSchedule();
      this.getAttendanceStatus();
    },

    getStudentsFromSelectedSchedule() {
      this.formattedStudentClassList = [];
      this.allStudentsFromSchedule = [];
      this.fetchingScheduleStudents = true;

      //fetch students
      studentClassRepository
        .getAttendanceBySchedule(
          this.selectedSchedule.idschedule,
          this.currentUserPersonalInfo.id_user,
          this.deferredAttendanceRequest.date
        )
        .then(({ data }) => {
          this.allStudentsFromSchedule = data.data;

          this.allStudentsFromSchedule.forEach((student) => {
            let studentItem = {
              student_class_id: student.studentClassId,
              old_attendance_status_id: student.attendance_status_id,
              new_attendance_status_id: student.attendance_status_id,

              //just to show some student info on the table
              full_name: student.full_name,
              photo: student.photo,
              code: student.code,
              is_justified: student.is_justified,

              //for later agrupation
              section_group: student.section_group,
              technical_group: student.technical_group,
            };

            this.formattedStudentClassList.push(studentItem);
          });
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar el formulario correctamente",
          });
        })
        .finally(() => {
          this.fetchingScheduleStudents = false;
        });
    },

    filterAllowedDates(date) {
      // Checking date is a JavaScript Date object
      if (!(date instanceof Date)) {
        date = new Date(date);
      }

      // Getting the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = date.getDay();

      // Checking if the day of the week matches the selected day
      return dayOfWeek === this.selectedSchedule.day.id - 1;
    },

    setAttendanceStatusTheme(attendaceStatus) {
      //evaluating attendance status ID's
      let attendanceStatusSchemas = {
        1: {
          color: "green",
        },
        2: {
          color: "red",
        },
        3: {
          color: "orange",
        },
        4: {
          color: "blue-grey",
        },
        5: {
          color: "purple",
        },
      };

      let defaultSchema = {
        color: "grey",
      };

      if (attendanceStatusSchemas.hasOwnProperty(attendaceStatus)) {
        return attendanceStatusSchemas[attendaceStatus];
      } else {
        return defaultSchema;
      }
    },

    getCurrentDay() {
      // Set locale to Spanish
      dayjs.locale("es");

      const today = dayjs();
      this.currentDay = today.format("dddd, DD [de] MMMM [de] YYYY");

      this.setCurrentDay();
    },

    setCurrentDay() {
      this.date = dayjs().format("YYYY-MM-DD");
    },

    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.deferredAttendanceRequest.justification =
        this.deferredAttendanceRequest.justification + paste;
    },

    handleKeyUpMotive() {
      this.$v.deferredAttendanceRequest.justification.$touch();
      if (!this.$v.deferredAttendanceRequest.justification.$invalid) {
        this.stepperActiveStep = 2;
      }
    },

    totalStudentStats() {
      let notValidAttendanceStudentsCount = 0;
      for (
        let index = 0;
        index < this.formattedStudentClassList.length;
        index++
      ) {
        const student = this.formattedStudentClassList[index];
        if (student.new_attendance_status_id == this.PENDING_ATTENDACE_ID) {
          notValidAttendanceStudentsCount++;
        }
      }
      return {
        totalStudents: this.formattedStudentClassList.length,
        notValidAttendaceStudents: notValidAttendanceStudentsCount,
      };
    },
  },

  watch: {
    motive(newVal) {
      if (!!this.deferredAttendanceRequest.justification) {
        this.deferredAttendanceRequest.justification = newVal.replace(
          / {3,}/g,
          " "
        );
      }
    },
    dialogActiveState(newValue) {
      if (newValue) {
        this.getRequestReasons();
      }
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentPageActions"]),

    tableManage() {
      if (this.selectedSchedule.isTechnical) {
        return "section_group";
      } else {
        return "technical_group";
      }
    },

    selectedScheduleDay() {
      return this.selectedSchedule.day.id;
    },

    dayHasBeenSelected() {
      return this.selectedDayFormatted !== "Invalid Date";
    },

    today() {
      let date = new Date();
      //includes time
      return date.toISOString();
    },

    selectedDayFormatted() {
      return this.fullFormattedDate(this.date);
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  validations: {
    formattedStudentClassList: {
      new_attendance_status_id: {
        required: withParams({ type: "required" }, (value) => required(value)),
        isSpecificValue: withParams(
          { type: "isSpecificValue", value: 4 },
          (value) => {
            return value === this.PENDING_ATTENDACE_ID;
          }
        ),
      },
    },
    deferredAttendanceRequest: {
      date: {
        required,
      },
      justification: {
        required,
      },
      request_reason_id: {
        required,
      },
    },
  },
};
</script>
