<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="1000"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar horario</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" class="pb-0">
                <form-group name="día" :validator="$v.scheduleData.day.id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Día"
                    :loading="days.isLoading"
                    :items="days.data"
                    item-value="id"
                    item-text="name"
                    return-object
                    rounded
                    filled
                    v-model="scheduleData.day.id"
                    @input="loadClassTimeByDay(scheduleData.day_id)"
                  >
                  </v-select>
                </form-group>
              </v-col>
              <v-col cols="6" class="pb-0">
                <form-group
                  name="bloque"
                  :validator="$v.scheduleData.class_time"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Bloque"
                    required
                    rounded
                    filled
                    :items="classTime.data"
                    item-value="daily_block_id"
                    return-object
                    :loading="classTime.isLoading"
                    v-model="scheduleData.class_time"
                  >
                    <template v-slot:selection="{ item }">
                      <span>{{
                        `Inicio: ${item.start_time} | Fin: ${item.end_time}`
                      }}</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <span>
                            Hora inicio:
                            <strong class="ml-1"
                              >{{ data.item.start_time }} -</strong
                            >
                            Hora fin:
                            <strong class="ml-1">
                              {{ data.item.end_time }}</strong
                            >
                            Tipo:
                            <strong class="ml-1">{{
                              data.item.durationType
                            }}</strong>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0">
                <form-group
                  name="Asignatura"
                  :validator="$v.scheduleData.teacher_group"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="subjects.isLoading"
                    :items="subjects.data"
                    :disabled="subjects.isLoading"
                    item-value="id"
                    return-object
                    label="Asignatura"
                    required
                    rounded
                    filled
                    @input="setTeacherDate()"
                    v-model="scheduleData.teacher_group"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="text-subtitle-2">{{
                        `${item.name} ${item.grade_information}`
                      }}</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <span>
                            <strong class="ml-1">{{ data.item.name }}</strong>
                            <strong class="ml-1">
                              {{ data.item.grade_information }}</strong
                            >
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </form-group>
              </v-col>
              <v-col cols="2">
                <form-group
                  name="sección"
                  :validator="$v.scheduleData.technicalSection"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="sections.isLoading"
                    :items="sections.data"
                    return-object
                    item-value="id"
                    item-text="section"
                    label="Sección"
                    required
                    rounded
                    filled
                    v-model="scheduleData.technicalSection"
                  >
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pb-0">
                <v-menu
                  v-model="date1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha de inicio"
                      v-bind="attrs"
                      :validator="$v.scheduleData.start_date"
                    >
                      <v-text-field
                        v-model="scheduleData.start_date"
                        label="Fecha de inicio"
                        prepend-inner-icon="mdi-calendar"
                        required
                        rounded
                        filled
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                        :disabled="
                          scheduleData.teacher_group?.subject_type ==
                          'Académica'
                        "
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="scheduleData.start_date"
                    @input="date1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-menu
                  v-model="date2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha fin"
                      v-bind="attrs"
                      :validator="$v.scheduleData.end_date"
                    >
                      <v-text-field
                        v-model="scheduleData.end_date"
                        label="Fecha fin"
                        prepend-inner-icon="mdi-calendar"
                        required
                        rounded
                        filled
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                        :disabled="
                          scheduleData.teacher_group?.subject_type ==
                          'Académica'
                        "
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="scheduleData.end_date"
                    @input="date2 = false"
                    :min="scheduleData.start_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="pb-0">
                <form-group name="Local" :validator="$v.scheduleData.local">
                  <v-autocomplete
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="locals.isLoading"
                    :items="locals.data"
                    return-object
                    item-value="id"
                    item-text="name"
                    label="Local"
                    required
                    rounded
                    filled
                    v-model="scheduleData.local"
                  ></v-autocomplete>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <ResponseScheduleModal
      ref="mdlResponse"
      :data="response"
      :sections="this.sections.data"
    ></ResponseScheduleModal>
  </v-dialog>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import schedulesRepository from "@/repositories/schedulesRepository";
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import localsRepository from "@/repositories/localsRepository";
import daysRepository from "@/repositories/daysRepository";
import dailyBlockDetailsRepository from "@/repositories/dailyBlockDetailsRepository";
import ResponseScheduleModal from "@/components/modals/schedules/ResponseScheduleModal";
import { mapGetters } from "vuex";

export default {
  name: "UpdateScheduleModal",
  components: {
    ResponseScheduleModal,
  },
  props: {
    scheduleData: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      date1: false,
      date2: false,
      response: null,
      days: {
        data: [],
        isLoading: false,
      },
      subjects: {
        data: [],
        isLoading: false,
      },
      locals: {
        data: [],
        isLoading: false,
      },
      classTime: {
        data: [],
        isLoading: false,
      },
      sections: {
        isLoading: false,
        data: [],
      },
      schedule: {
        local_id: null,
        teacher_group: null,
        day_id: null,
        id_user: null,
        start_date: null,
        end_date: null,
      },
      TechnicalGroupableType: "App\\Models\\GradeSpecialityGroup",
      scheduleArray: [],
    };
  },
  methods: {
    showMdlResponse() {
      this.$refs.mdlResponse.toggleModal();
    },
    setTeacherDate() {
      if (this.schedule.teacher_group?.subject_type == "Académica") {
        stageRepository
          .getYearStartAndEndDate(this.schedule.teacher_group.grade_id)
          .then(({ data }) => {
            this.schedule.start_date = data.dates.start;
            this.schedule.end_date = data.dates.end;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar las fechas de inicio y fin",
            });
          });
      }
    },
    loadSubjectTeacherGroups() {
      this.subjects.isLoading = true;
      subjectTeacherRepository
        .getSubjectTeacherGroup(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    loadLocals() {
      this.locals.isLoading = true;
      localsRepository
        .getAllLocals()
        .then(({ data }) => {
          this.locals.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.locals.isLoading = false;
        });
    },
    loadDays() {
      this.days.isLoading = true;
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.days.isLoading = false;
        });
    },
    loadClassTimeByDay(day_id) {
      if (this.scheduleData.teacher_group?.subject_type == "Modular") {
        this.classTime.isLoading = true;
        dailyBlockDetailsRepository
          .getClassTimeTechnicalByDay(day_id)
          .then(({ data }) => {
            this.classTime.data = data.data;
          })
          .catch((error) => {
            this.fireToast({
              icon: "error",
              title: error.message,
            });
          })
          .finally(() => {
            this.classTime.isLoading = false;
          });
      } else if (this.scheduleData.teacher_group?.subject_type == "Académica") {
        this.classTime.isLoading = true;
        dailyBlockDetailsRepository
          .getClassTimeAcademicByDay(day_id)
          .then(({ data }) => {
            this.classTime.data = data.data;
          })
          .catch((error) => {
            this.fireToast({
              icon: "error",
              title: error.message,
            });
          })
          .finally(() => {
            this.classTime.isLoading = false;
          });
      }
    },
    loadSectionGrade() {
      this.sections.isLoading = true;
      technicalGroupRepository
        .getTechnicalSections(
          this.scheduleData?.teacher_group.teacher_groupable_id
        )
        .then(({ data }) => {
          this.sections.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.sections.isLoading = false;
        });
    },

    formattedData() {
      const formatted = {
        teacher_group_id: this.scheduleData.teacher_group.id,
        daily_block_detail_id: this.scheduleData.class_time.daily_block_id,
        start_date: this.scheduleData.start_date,
        end_date: this.scheduleData.end_date,
        id_user: this.scheduleData.iduser,
        local_id: this.scheduleData.local.id,
        section_id: this.scheduleData.technicalSection
          ? this.scheduleData.technicalSection.id
          : null,
        start_time: this.scheduleData.class_time.start_time,
        end_time: this.scheduleData.class_time.end_time,
        day_id: this.scheduleData.day.id,
        day_name: this.scheduleData.day.name,
        local_name: this.scheduleData.local.name,
        subject_name: this.scheduleData.teacher_group.name,
        grade_information: this.scheduleData.teacher_group.grade_information,
      };
      this.update(formatted);
    },

    update(data) {
      this.isLoading = true;
      schedulesRepository
        .updateSchedule(
          this.scheduleData.idschedule,
          this.scheduleData.teacher_groups_date,
          data
        )
        .then(({ data }) => {
          this.response = data;
          this.showMdlResponse();
          this.$emit("reloadSchedule");
          this.closeModal();
        })
        .catch((error) => {
          console.error(error);
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.$v.$reset();
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.formattedData();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  validations: {
    scheduleData: {
      day: {
        id: {
          required,
        },
      },
      class_time: {
        required,
      },
      teacher_group: {
        required,
      },
      technicalSection: {
        required: requiredIf(function () {
          return this.schedule.teacher_group?.subject_type == "Modular";
        }),
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      local: {
        required,
      },
    },
  },
  watch: {
    dialog() {
      if (!!this.currentUserPersonalInfo.id_user != null) {
        this.loadLocals();
        this.loadDays();
        this.loadSubjectTeacherGroups();
      }
    },
    "scheduleData.day"() {
      this.loadClassTimeByDay(this.scheduleData.day.id);
    },
    "scheduleData.teacher_group"() {
      this.loadClassTimeByDay(this.scheduleData.day.id);
      this.loadSectionGrade();
    },
  },
};
</script>
