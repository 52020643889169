<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="800"
    persistent
  >
    <v-card>
      <v-toolbar dark color="info" class="elevation-0 px-3">
        <span class="headline">Resumen de horarios</span>
      </v-toolbar>
      <v-card-text>
        <v-card
          v-for="(item, index) in data?.data"
          :key="index"
          outlined
          class="mt-5 rounded-lg elevation-0"
        >
          <!-- Detalle del horario que se guardo correctamente -->
          <v-container fluid v-if="item.status" class="ma-0 pa-0">
            <!-- Mensaje y detalle del día y del bloque de clases del horario -->
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="d-flex flex-column align-center">
                <v-avatar :color="`green lighten-4`" size="75" class="mb-2">
                  <v-icon dark :color="`success`" large>
                    mdi-check-circle-outline
                  </v-icon>
                </v-avatar>
                <p class="font-weight-bold text-subtitle-1 mb-0">
                  {{ item.message }}
                </p>
                <p class="font-weight-medium text-body-1 grey--text mb-0">
                  {{ item.scheduleData?.dailyBlockDetail?.Day.name }},
                  {{
                    item.scheduleData?.dailyBlockDetail?.ClassTime.start_time
                  }}
                  -
                  {{ item.scheduleData?.dailyBlockDetail?.ClassTime.end_time }}
                </p>
              </v-col>
            </v-row>
            <v-divider class="d-none d-md-flex ma-0"></v-divider>
            <!-- Detalle de la asignatura y grupo del horario -->
            <v-row class="ma-0 pa-0">
              <v-col cols="12" md="6" class="d-flex flex-column align-center"
                ><v-chip :color="`${'blue lighten-4'}`" class="mb-2">
                  <v-icon :color="`${'blue'}`" center>
                    mdi-account-circle-outline
                  </v-icon>
                </v-chip>
                <p class="font-weight-bold text-body-1 mb-0 text-center">
                  {{ item.scheduleData?.subject }}
                </p>
                <p class="font-weight-medium text-body-2 grey--text">
                  {{ item.scheduleData?.user?.first_name }}
                  {{ item.scheduleData?.user?.last_name }}
                </p>
              </v-col>
              <v-divider vertical class="d-none d-md-flex"></v-divider>
              <v-col cols="12" md="6" class="d-flex flex-column align-center">
                <v-chip :color="`${'deep-purple lighten-4'}`" class="mb-2">
                  <v-icon :color="`${'deep-purple'}`" center>
                    mdi-account-group-outline
                  </v-icon>
                </v-chip>
                <p class="font-weight-bold text-body-1 mb-0">
                  {{ item.scheduleData?.grade }}
                  <span
                    v-if="
                      !(
                        !!item.scheduleData?.group &&
                        !!item.scheduleData?.section
                      )
                    "
                  >
                    {{ item.scheduleData?.section }}</span
                  >
                </p>
                <p class="font-weight-medium text-body-1 mb-0">
                  {{ item.scheduleData?.speciality }}
                  {{ item.scheduleData?.group }}
                  <span
                    v-if="
                      !!item.scheduleData?.group && !!item.scheduleData?.section
                    "
                    >- {{ item.scheduleData?.section }}</span
                  >
                </p>
                <p class="font-weight-medium text-body-2 grey--text">
                  {{ item.scheduleData?.local }}
                </p>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid v-else class="ma-0 pa-0">
            <!--Detalle del horario con conflicto-->

            <!-- ***********PRIMERA SECCION********** -->

            <v-row class="ma-0 pa-0 justify-center">
              <v-col cols="8" class="d-flex flex-column align-center mb-n4">
                <v-avatar :color="`red lighten-4`" size="75" class="mb-2">
                  <v-icon dark :color="`error`" large>
                    mdi-close-circle-outline
                  </v-icon>
                </v-avatar>
                <p class="text-center font-weight-bold text-subtitle-1 mb-0">
                  No se pudo registrar este horario:
                </p>
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="d-flex flex-column align-center justify-center mb-n3"
              >
                <v-card
                  outlined
                  class="rounded-lg grey lighten-4 elevation-0 pa-4 d-flex flex-column align-center justify-center"
                >
                  <p class="font-weight-medium text-body-2 grey--text mb-0 text-center">
                    <span class="font-weight-bold grey--text text--darken-2">
                      {{ item.conflicted_schedule?.subject }}</span
                    >,
                  </p>
                  <p class="font-weight-medium text-body-2 grey--text mb-0">
                    El
                    <span class="font-weight-bold grey--text text--darken-2">
                      {{ item.conflicted_schedule?.day }}</span
                    >
                    entre
                    <span class="font-weight-bold grey--text text--darken-2">
                      {{ item.conflicted_schedule?.block }}</span
                    >, en
                    <span class="font-weight-bold grey--text text--darken-2">
                      {{ item.conflicted_schedule?.local }}</span
                    >.
                  </p>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="10"
                class="d-flex flex-column align-center justify-center"
              >
                <p class="text-center font-weight-bold text-subtitle-1 mb-0">
                  Debido a que
                  <span class="text-lowercase">{{ item.message }}.</span>
                </p>
              </v-col>
            </v-row>

            <v-divider class="d-none d-md-flex ma-0"></v-divider>

            <!-- ***********SEGUNDA SECCION********** -->

            <!-- Detalle del día y bloque del horario con el que tiene conflicto -->
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="d-flex flex-column align-center">
                <p class="text-center font-weight-bold text-subtitle-1 mb-0">
                  Horario ya existente que causa conflicto con el antes
                  mencionado:
                </p>
                <p class="font-weight-medium text-body-1 grey--text mb-0">
                  <span class="font-weight-bold grey--text text--darken-2">{{
                    item.scheduleData?.dailyBlockDetail?.Day.name
                  }}</span>
                  , entre
                  <span class="font-weight-bold grey--text text--darken-2">{{
                    item.scheduleData?.dailyBlockDetail?.ClassTime.start_time
                  }}</span>
                  y
                  <span class="font-weight-bold grey--text text--darken-2">{{
                    item.scheduleData?.dailyBlockDetail?.ClassTime.end_time
                  }}</span>
                </p>
              </v-col>
            </v-row>

            <!-- Detalle de la asignatura encontrada con la que se tiene conflicto -->
            <v-row class="ma-0 pa-0">
              <v-col cols="12" md="6" class="d-flex flex-column align-center"
                ><v-chip :color="`${'blue lighten-4'}`" class="mb-2">
                  <v-icon :color="`${'blue'}`" center>
                    mdi-account-circle-outline
                  </v-icon>
                </v-chip>
                <p class="font-weight-bold text-body-1 mb-0 text-center">
                  {{ item.scheduleData?.subject }}
                </p>
                <p class="font-weight-medium text-body-2 grey--text">
                  Impartido por
                  <span class="grey--text text--darken-3">
                    {{ item.scheduleData?.user?.first_name }}
                    {{ item.scheduleData?.user?.last_name }}
                  </span>
                </p>
              </v-col>

              <v-col cols="12" md="6" class="d-flex flex-column align-center">
                <v-chip :color="`${'deep-purple lighten-4'}`" class="mb-2">
                  <v-icon :color="`${'deep-purple'}`" center>
                    mdi-account-group-outline
                  </v-icon>
                </v-chip>
                <p class="font-weight-bold text-body-1 mb-0">
                  {{ item.scheduleData?.grade }}
                  <span
                    v-if="
                      !(
                        !!item.scheduleData?.group &&
                        !!item.scheduleData?.section
                      )
                    "
                  >
                    {{ item.scheduleData?.section }}</span
                  >
                </p>
                <p class="font-weight-medium text-body-1 mb-0">
                  {{ item.scheduleData?.speciality }}
                  {{ item.scheduleData?.group }}
                  <span
                    v-if="
                      !!item.scheduleData?.group && !!item.scheduleData?.section
                    "
                    >- {{ item.scheduleData?.section }}</span
                  >
                </p>
                <p class="font-weight-medium text-body-2 grey--text">
                  En
                  <span class="grey--text text--darken-3">
                    {{ item.scheduleData?.local }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end pt-0">
        <v-btn color="info" dark depressed @click="toggleModal">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import IconCard from "@/components/elements/dashboard/IconCard.vue";

export default {
  name: "ResponseScheduleModal",
  components: {
    IconCard,
  },
  props: {
    data: {
      type: Object,
    },
    sections: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {},
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    timeAndDay() {},
  },
};
</script>
<style>
.custom-container {
  background-color: #e9f5ea;
}
</style>
