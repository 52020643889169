<template>
  <v-dialog v-model="dialog" width="500">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="blue lighten-4" size="85">
              <v-icon dark large color="blue"> mdi-information-outline </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              ¿Cambiar estado de estudiantes?
            </p>
            <p class="text-center text-body-1 font-weight-medium">
              Los estudiantes que tengan el estado
              <span class="font-weight-bold">"Pendiente de asistencia"</span> se
              les asignará el estado
              <span class="font-weight-bold">"Presente"</span> .
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text outlined @click="dialog = false">
          no, regresar
        </v-btn>
        <v-btn
          color="success white--text font-weight-medium"
          @click="emitAction()"
        >
          si, continuar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HandleAttendanceStateChangeModal",
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    emitAction() {
      this.$emit("action");
      this.toggleModal();
    },
  },
};
</script>