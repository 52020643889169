<template>
  <v-dialog v-model="dialog" width="550">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="amber lighten-4" size="85">
              <v-icon dark large color="amber">
                mdi-information-outline
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              {{ alertMessage }}
            </p>
            <p class="text-center text-body-1 font-weight-medium">
              Para poder generar una solicitud de asistencia diferida, debe
              realizar almenos un cambio de estado de asistencia a un estudiante
              de la lista.
            </p>
            <v-divider></v-divider>
            <p class="text-center text-body-1 font-weight-medium">
              Adicional a lo antes mencionado,
              <span class="font-weight-bold"
                >todos los estudiantes en la lista</span
              >
              deben contener un estado de asistencia válido antes de poder
              generar la solicitud.
            </p>
            <v-card class="elevation-0 mt-7">
              <div class="d-flex justify-space-around">
                <v-chip :color="`blue-grey lighten-5`" small class="mr-sm-3">
                  <span :class="`blue-grey--text font-weight-bold`"
                    >Pendiente de asistencia</span
                  >
                </v-chip>

                <v-icon>mdi-arrow-right</v-icon>

                <v-chip :color="`green lighten-5`" small class="ml-sm-3">
                  <span :class="`success--text font-weight-bold`"
                    >Presente</span
                  >
                </v-chip>
              </div>
              <div class="d-flex justify-space-around mt-3">
                <v-chip :color="`blue-grey lighten-5`" small class="mr-sm-3">
                  <span :class="`blue-grey--text font-weight-bold`"
                    >Pendiente de asistencia</span
                  >
                </v-chip>

                <v-icon>mdi-arrow-right</v-icon>

                <v-chip :color="`red lighten-5`" small class="ml-sm-3">
                  <span :class="`red--text font-weight-bold`">Ausente</span>
                </v-chip>
              </div>
              <div class="d-flex justify-space-around mt-3">
                <v-chip :color="`blue-grey lighten-5`" small class="mr-sm-3">
                  <span :class="`blue-grey--text font-weight-bold`"
                    >Pendiente de asistencia</span
                  >
                </v-chip>

                <v-icon>mdi-arrow-right</v-icon>

                <v-chip :color="`orange lighten-5`" small class="ml-sm-3">
                  <span :class="`orange--text font-weight-bold`"
                    >Llegada tarde a clase</span
                  >
                </v-chip>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text outlined @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NoChangedStatusFoundInformationModal",
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    alertMessage: {
      type: String,
      default:
        "No se encontraron cambios o hay estudiantes sin registro de asistencia",
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>