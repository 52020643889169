<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="deep-purple" class="elevation-0 px-3">
            <span class="headline"
              >Resumen de solicitud de asistencia diferida</span
            >
          </v-toolbar>
          <v-card-text>
            <v-card class="elevation-0 rounded-lg pa-4 mb-5">
              <p class="text-h5 font-weight-bold mb-0">
                {{ schedule.teacher_group?.name }}
              </p>
              <p class="text-body-1 font-weight-medium">
                {{ schedule.teacher_group?.grade_information }}
              </p>
              <p class="mb-0">
                {{ schedule.isTechnical ? "Este módulo" : "Esta asignatura" }}
                se imparte entre
                <span class="font-weight-bold"
                  >{{ schedule.class_time?.start_time }} y
                  {{ schedule.class_time?.end_time }}.</span
                >
              </p>
              <p class="mb-0">
                Desde el
                <span class="font-weight-bold">{{
                  fullFormattedDate(schedule.start_date)
                }}</span>
                hasta el
                <span class="font-weight-bold">{{
                  fullFormattedDate(schedule.end_date)
                }}</span
                >.
              </p>
            </v-card>
            <p class="text-body-1 font-weight-medium mx-5">
              Se modificará el listado de registros de asistencia del día
              <span class="font-weight-bold">{{
                fullFormattedDate(payload.date)
              }}</span>
              con estos cambios (cuando sea aprobada la solicitud):
            </p>

            <!-- ------------ -->
            <v-card class="elevation-0 rounded-lg" outlined>
              <v-list>
                <v-list-item
                  v-for="(student, index) in payload.student_class"
                  :key="index"
                  class="d-flex flex-column align-start pb-2 pt-1"
                  :style="`${
                    index === payload.student_class.length - 1
                      ? ''
                      : 'border-bottom: 2px solid #eee'
                  }`"
                >
                  <div class="d-flex">
                    <v-list-item-avatar>
                      <v-img
                        :src="student.photo"
                        :alt="student.full_name"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{
                        student.code
                      }}</v-list-item-title>

                      <v-list-item-subtitle class="font-weight-medium">{{
                        student.full_name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </div>

                  <div class="d-flex justify-space-between mb-n10 mt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          class="mr-sm-4"
                          v-bind="attrs"
                          v-on="on"
                          :color="`${
                            setAttendanceStatusTheme(
                              student.old_attendance_status_id
                            ).color
                          } lighten-5`"
                          small
                        >
                          <span
                            :class="`${
                              setAttendanceStatusTheme(
                                student.old_attendance_status_id
                              ).color
                            }--text font-weight-bold`"
                            >{{
                              formattedAttendanceStatus(
                                student.old_attendance_status_id
                              )?.name
                            }}</span
                          >
                        </v-chip>
                      </template>
                      <span>Estado de asistencia anterior</span>
                    </v-tooltip>
                    <v-icon>mdi-arrow-right</v-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          class="ml-sm-4"
                          v-bind="attrs"
                          v-on="on"
                          :color="`${
                            setAttendanceStatusTheme(
                              student.new_attendance_status_id
                            ).color
                          } lighten-5`"
                          small
                        >
                          <span
                            :class="`${
                              setAttendanceStatusTheme(
                                student.new_attendance_status_id
                              ).color
                            }--text font-weight-bold`"
                            >{{
                              formattedAttendanceStatus(
                                student.new_attendance_status_id
                              )?.name
                            }}</span
                          >
                        </v-chip>
                      </template>
                      <span>Estado de asistencia actualizado</span>
                    </v-tooltip>
                  </div>
                </v-list-item>
              </v-list>
            </v-card>

            <p class="text-h6 font-weight-bold mt-6 ml-2 mb-2">
              Razón de la solicitud:
            </p>
            <p class="text-body-1 font-weight-normal ml-2 text-justify">
              {{ formattedRequestReasons(payload.request_reason_id)?.name }}.
            </p>

            <p class="text-h6 font-weight-bold mt-6 ml-2 mb-2">
              Motivo de solicitud:
            </p>
            <p
              class="text-body-1 font-weight-normal ml-2 font-italic text-justify"
            >
              <span class="font-weight-bold">" </span>{{ payload.justification
              }}<span class="font-weight-bold"> "</span>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn
              :disabled="isSavingRequest"
              text
              outlined
              class="mr-1"
              @click="toggleModal()"
              >Regresar y modificar</v-btn
            >
            <v-btn
              text
              outlined
              :loading="isSavingRequest"
              :disabled="isSavingRequest"
              class="success ml-1 white--text font-weight-bold"
              @click="sendRequest()"
              >Generar solicitud</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <HandleSaveErrorModal
      ref="MdlHandleSaveError"
      :errorMessage="errorMessage"
    ></HandleSaveErrorModal>
  </div>
</template>

<script>
import attendanceTimeLimitRepository from "@/repositories/attendanceTimeLimitRepository";
import HandleSaveErrorModal from "@/components/modals/attendance/HandleSaveErrorModal.vue";
export default {
  name: "ConfirmDeferredAttendanceRequestModal",
  components: {},
  data() {
    return {
      errorMessage: "",
      dialog: false,
      isSavingRequest: false,
    };
  },
  props: {
    schedule: {
      type: Object,
    },

    payload: {
      type: Object,
    },

    attendanceStatuses: {
      type: Array,
    },

    requestReasons: {
      type: Array,
    },
  },
  components: {
    HandleSaveErrorModal,
  },
  methods: {
    sendRequest() {
      this.isSavingRequest = true;
      attendanceTimeLimitRepository
        .registerDeferredAttendanceRequest(this.payload)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Solicitud registrada con exito",
          });
          this.toggleModal();
          this.$emit("successfullySaved");
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
          this.$refs.MdlHandleSaveError.toggleModal();
        })
        .finally(() => {
          this.isSavingRequest = false;
        });
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    formattedAttendanceStatus(id) {
      let foundStatus = this.attendanceStatuses.find((status) => {
        return status.id === id;
      });
      return foundStatus;
    },

    formattedRequestReasons(id) {
      let foundReason = this.requestReasons.find((status) => {
        return status.id === id;
      });
      return foundReason;
    },

    setAttendanceStatusTheme(attendaceStatus) {
      //evaluating attendance status ID's
      let attendanceStatusSchemas = {
        1: {
          color: "green",
        },
        2: {
          color: "red",
        },
        3: {
          color: "orange",
        },
        4: {
          color: "blue-grey",
        },
        5: {
          color: "purple",
        },
      };

      let defaultSchema = {
        color: "grey",
      };

      if (attendanceStatusSchemas.hasOwnProperty(attendaceStatus)) {
        return attendanceStatusSchemas[attendaceStatus];
      } else {
        return defaultSchema;
      }
    },
  },
  computed: {},
};
</script>