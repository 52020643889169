var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-0 px-3",attrs:{"dark":"","color":"deep-purple"}},[_c('span',{staticClass:"headline"},[_vm._v("Resumen de solicitud de asistencia diferida")])]),_c('v-card-text',[_c('v-card',{staticClass:"elevation-0 rounded-lg pa-4 mb-5"},[_c('p',{staticClass:"text-h5 font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.schedule.teacher_group?.name)+" ")]),_c('p',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.schedule.teacher_group?.grade_information)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.schedule.isTechnical ? "Este módulo" : "Esta asignatura")+" se imparte entre "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.schedule.class_time?.start_time)+" y "+_vm._s(_vm.schedule.class_time?.end_time)+".")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" Desde el "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.fullFormattedDate(_vm.schedule.start_date)))]),_vm._v(" hasta el "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.fullFormattedDate(_vm.schedule.end_date)))]),_vm._v(". ")])]),_c('p',{staticClass:"text-body-1 font-weight-medium mx-5"},[_vm._v(" Se modificará el listado de registros de asistencia del día "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.fullFormattedDate(_vm.payload.date)))]),_vm._v(" con estos cambios (cuando sea aprobada la solicitud): ")]),_c('v-card',{staticClass:"elevation-0 rounded-lg",attrs:{"outlined":""}},[_c('v-list',_vm._l((_vm.payload.student_class),function(student,index){return _c('v-list-item',{key:index,staticClass:"d-flex flex-column align-start pb-2 pt-1",style:(`${
                  index === _vm.payload.student_class.length - 1
                    ? ''
                    : 'border-bottom: 2px solid #eee'
                }`)},[_c('div',{staticClass:"d-flex"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":student.photo,"alt":student.full_name}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(student.code))]),_c('v-list-item-subtitle',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(student.full_name))])],1)],1),_c('div',{staticClass:"d-flex justify-space-between mb-n10 mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-sm-4",attrs:{"color":`${
                          _vm.setAttendanceStatusTheme(
                            student.old_attendance_status_id
                          ).color
                        } lighten-5`,"small":""}},'v-chip',attrs,false),on),[_c('span',{class:`${
                            _vm.setAttendanceStatusTheme(
                              student.old_attendance_status_id
                            ).color
                          }--text font-weight-bold`},[_vm._v(_vm._s(_vm.formattedAttendanceStatus( student.old_attendance_status_id )?.name))])])]}}],null,true)},[_c('span',[_vm._v("Estado de asistencia anterior")])]),_c('v-icon',[_vm._v("mdi-arrow-right")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-sm-4",attrs:{"color":`${
                          _vm.setAttendanceStatusTheme(
                            student.new_attendance_status_id
                          ).color
                        } lighten-5`,"small":""}},'v-chip',attrs,false),on),[_c('span',{class:`${
                            _vm.setAttendanceStatusTheme(
                              student.new_attendance_status_id
                            ).color
                          }--text font-weight-bold`},[_vm._v(_vm._s(_vm.formattedAttendanceStatus( student.new_attendance_status_id )?.name))])])]}}],null,true)},[_c('span',[_vm._v("Estado de asistencia actualizado")])])],1)])}),1)],1),_c('p',{staticClass:"text-h6 font-weight-bold mt-6 ml-2 mb-2"},[_vm._v(" Razón de la solicitud: ")]),_c('p',{staticClass:"text-body-1 font-weight-normal ml-2 text-justify"},[_vm._v(" "+_vm._s(_vm.formattedRequestReasons(_vm.payload.request_reason_id)?.name)+". ")]),_c('p',{staticClass:"text-h6 font-weight-bold mt-6 ml-2 mb-2"},[_vm._v(" Motivo de solicitud: ")]),_c('p',{staticClass:"text-body-1 font-weight-normal ml-2 font-italic text-justify"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\" ")]),_vm._v(_vm._s(_vm.payload.justification)),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" \"")])])],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end pt-0"},[_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":_vm.isSavingRequest,"text":"","outlined":""},on:{"click":function($event){return _vm.toggleModal()}}},[_vm._v("Regresar y modificar")]),_c('v-btn',{staticClass:"success ml-1 white--text font-weight-bold",attrs:{"text":"","outlined":"","loading":_vm.isSavingRequest,"disabled":_vm.isSavingRequest},on:{"click":function($event){return _vm.sendRequest()}}},[_vm._v("Generar solicitud")])],1)],1)],1)],1),_c('HandleSaveErrorModal',{ref:"MdlHandleSaveError",attrs:{"errorMessage":_vm.errorMessage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }