<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="showMdlConfirmClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Registro de horario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="showMdlConfirmClose()"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- Modal content -->

      <v-container fluid>
        <v-row class="justify-center px-md-10">
          <v-col cols="12" sm="11" md="10" lg="12">
            <v-alert class="mt-5 mb-0" text outlined prominent type="info"
              >Para registrar los horarios, es necesario crear cada uno de ellos
              y agregarlos a la lista. Una vez que todos los horarios que quiera
              crear estén en la lista, puede proceder a guardarlos presionando
              el botón guardar.</v-alert
            >
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center px-md-15">
          <v-col cols="12" md="10" lg="7" xl="8" class="pb-0">
            <v-form>
              <v-card class="elevation-0">
                <v-card-text class="pa-0">
                  <!-- Day and Block -->
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <form-group
                          name="Asignatura"
                          :validator="$v.schedule.teacher_group"
                        >
                          <v-select
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            :loading="subjects.isLoading"
                            :items="subjects.data"
                            :disabled="subjects.isLoading"
                            item-value="id"
                            return-object
                            label="Asignatura"
                            required
                            rounded
                            filled
                            @input="
                              setTeacherDate();
                              loadSectionGrade();
                            "
                            v-model="schedule.teacher_group"
                          >
                            <template v-slot:selection="{ item }">
                              <span class="text-subtitle-2">{{
                                `${item.name} ${item.grade_information}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <span>
                                    <strong class="ml-1">{{
                                      data.item.name
                                    }}</strong>
                                    <strong class="ml-1">
                                      {{ data.item.grade_information }}</strong
                                    >
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </form-group>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>

                  <!-- Schedule Details -->
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0">
                        <form-group name="día" :validator="$v.days.selectedDay">
                          <v-select
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            label="Día"
                            :loading="days.isLoading"
                            :items="days.data"
                            item-text="name"
                            return-object
                            rounded
                            filled
                            v-model="days.selectedDay"
                          >
                          </v-select>
                        </form-group>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <form-group
                          name="bloque"
                          :validator="$v.classTime.selectDailyBlock"
                        >
                          <v-select
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            label="Bloque"
                            required
                            rounded
                            filled
                            :items="classTime.data"
                            :disabled="!days.selectedDay"
                            item-value="id"
                            return-object
                            :loading="classTime.isLoading"
                            v-model="classTime.selectDailyBlock"
                          >
                            <template v-slot:selection="{ item }">
                              <span>{{
                                `Inicio: ${item.start_time} | Fin: ${item.end_time}`
                              }}</span>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  <span>
                                    Hora inicio:
                                    <strong class="ml-1"
                                      >{{ data.item.start_time }} -</strong
                                    >
                                    Hora fin:
                                    <strong class="ml-1">
                                      {{ data.item.end_time }} -</strong
                                    >
                                    Tipo:
                                    <strong class="ml-1">{{
                                      data.item.durationType
                                    }}</strong>
                                  </span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </form-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        class="pb-0"
                        v-if="verifySubjectType"
                      >
                        <form-group
                          name="sección"
                          :validator="$v.schedule.section"
                        >
                          <v-select
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            :loading="sections.isLoading"
                            :items="sections.data"
                            return-object
                            item-text="section"
                            label="Sección"
                            required
                            rounded
                            filled
                            :disabled="sections.isLoading"
                            v-model="schedule.section"
                          >
                          </v-select>
                        </form-group>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <form-group
                          name="Local"
                          :validator="$v.schedule.local_id"
                        >
                          <v-autocomplete
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            :loading="locals.isLoading"
                            :items="locals.data"
                            return-object
                            item-text="name"
                            label="Local"
                            required
                            rounded
                            filled
                            v-model="schedule.local_id"
                          ></v-autocomplete>
                        </form-group>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu
                          v-model="date1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <form-group
                              name="fecha de inicio"
                              :validator="$v.schedule.start_date"
                              v-bind="attrs"
                            >
                              <v-text-field
                                v-model="schedule.start_date"
                                label="Fecha de inicio"
                                prepend-inner-icon="mdi-calendar"
                                required
                                rounded
                                filled
                                v-bind="attrs"
                                slot-scope="{ attrs }"
                                v-on="on"
                                :disabled="
                                  schedule.teacher_group?.subject_type ==
                                  'Académica'
                                "
                              ></v-text-field>
                            </form-group>
                          </template>
                          <v-date-picker
                            v-model="schedule.start_date"
                            @input="date1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu
                          v-model="date2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <form-group
                              name="fecha fin"
                              :validator="$v.schedule.end_date"
                              v-bind="attrs"
                            >
                              <v-text-field
                                v-model="schedule.end_date"
                                label="Fecha fin"
                                prepend-inner-icon="mdi-calendar"
                                required
                                rounded
                                filled
                                v-bind="attrs"
                                slot-scope="{ attrs }"
                                v-on="on"
                                :disabled="
                                  schedule.teacher_group?.subject_type ==
                                  'Académica'
                                "
                              ></v-text-field>
                            </form-group>
                          </template>
                          <v-date-picker
                            v-model="schedule.end_date"
                            @input="date2 = false"
                            :min="schedule.start_date"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions
                  class="justify-end pt-0 mr-md-5"
                  v-if="days.selectedDay && classTime.selectDailyBlock"
                >
                  <v-btn
                    color="primary"
                    outlined
                    rounded
                    @click="addScheduleToList"
                    ><v-icon left>mdi-plus-circle-outline</v-icon> Añadir
                    horario a la lista</v-btn
                  >
                </v-card-actions>

                <v-container
                  v-if="!(days.selectedDay && classTime.selectDailyBlock)"
                >
                  <v-card
                    class="elevation-0 rounded-lg mt-5 d-flex justify-center align-center flex-column"
                  >
                    <v-avatar size="80" color="teal lighten-4"
                      ><v-icon color="teal" large
                        >mdi-calendar-cursor</v-icon
                      ></v-avatar
                    >
                    <p class="mt-3 font-weight-medium text-body-1 text-center">
                      Seleccione un día y un bloque para comenzar a crear un
                      horario.
                    </p>
                  </v-card>
                </v-container>
              </v-card>
            </v-form>
          </v-col>
          <!-- Right side list for accumulated schedules -->
          <v-col cols="12" md="10" lg="5" xl="4" class="pb-0">
            <div v-if="scheduleArray.length < 1">
              <v-card
                class="elevation-0 rounded-lg mt-5 pa-4 d-flex justify-center align-center flex-column"
                outlined
              >
                <v-avatar size="80" color="deep-orange lighten-4"
                  ><v-icon color="deep-orange" large
                    >mdi-calendar-plus</v-icon
                  ></v-avatar
                >
                <p class="mt-3 font-weight-medium text-body-1 text-center">
                  No se han añadido horarios para registrar.
                </p>
              </v-card>
            </div>
            <div v-if="scheduleArray.length > 0">
              <p class="font-weight-bold mb-4 mt-4 text-center text-h6">
                Horarios sin guardar
              </p>
              <v-list class="scheduleList">
                <v-list-item
                  v-for="(item, index) in scheduleArray"
                  :key="index"
                >
                  <v-list-item-avatar
                    :class="`${colors[index]} lighten-4 mt-n7`"
                    ><v-icon :color="`${colors[index]}`"
                      >mdi-calendar-badge-outline</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ item.schedule.teacher_group?.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="font-weight-medium"
                      >{{ item.day.name }} {{ item.classTime.start_time }} -
                      {{ item.classTime.end_time }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >{{ item.schedule.teacher_group?.grade_information }}
                      {{ item.schedule.section?.section }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="d-flex justify-space-between">
                      <v-chip
                        :color="`${
                          colors[index % 12]
                        } lighten-4 font-weight-medium`"
                        class="mt-1"
                      >
                        <v-icon :color="`${colors[index % 12]}`" left>
                          mdi-home-circle-outline
                        </v-icon>
                        {{ item.schedule.local_id.name }}
                      </v-chip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="deleteArrayItem(index)"
                            class="mr-5"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-container>
                <v-btn
                  color="success"
                  rounded
                  block
                  :loading="isLoading"
                  @click="formattedData"
                  >Guardar horarios listados</v-btn
                ></v-container
              >
            </div>
          </v-col>
        </v-row>
      </v-container>

      <ConfirmationModal
        ref="mdlOnCloseConfirmation"
        @confirmData="closeModal()"
      ></ConfirmationModal>

      <ResponseScheduleModal
        ref="mdlResponse"
        :data="response"
        :sections="this.sections.data"
        :scheduleData="this.formattedDataToStore"
      ></ResponseScheduleModal>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import schedulesRepository from "@/repositories/schedulesRepository";
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import localsRepository from "@/repositories/localsRepository";
import daysRepository from "@/repositories/daysRepository";
import dailyBlockDetailsRepository from "@/repositories/dailyBlockDetailsRepository";
import stageRepository from "@/repositories/stageRepository";
import ResponseScheduleModal from "@/components/modals/schedules/ResponseScheduleModal";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import { mapGetters } from "vuex";

export default {
  name: "CreateScheduleModal",
  components: {
    ResponseScheduleModal,
    ConfirmationModal,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      date1: false,
      date2: false,
      response: null,
      days: {
        data: [],
        isLoading: false,
        selectedDay: null,
      },
      subjects: {
        data: [],
        isLoading: false,
      },
      locals: {
        data: [],
        isLoading: false,
      },
      classTime: {
        data: [],
        isLoading: false,
        selectDailyBlock: null,
      },
      sections: {
        isLoading: false,
        data: [],
      },
      schedule: {
        local_id: null,
        teacher_group: null,
        day_id: null,
        id_user: null,
        start_date: null,
        end_date: null,
      },
      scheduleArray: [],
      formattedDataToStore: [],
    };
  },
  mounted() {
    this.loadLocals();
    this.loadDays();
    if (this.currentUserPersonalInfo.id_user != null) {
      this.loadSubjectTeacherGroups();
    }
  },
  methods: {
    loadClassTime() {
      if (!!this.days.selectedDay && !!this.schedule.teacher_group) {
        this.loadClassTimeByDay();
      }
    },
    deleteArrayItem(index) {
      this.scheduleArray.splice(index, 1);
    },
    setTeacherDate() {
      if (this.schedule.teacher_group?.subject_type == "Académica") {
        stageRepository
          .getYearStartAndEndDate(this.schedule.teacher_group.grade_id)
          .then(({ data }) => {
            this.schedule.start_date = data.dates.start;
            this.schedule.end_date = data.dates.end;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar las fechas de inicio y fin",
            });
          });
      }
    },
    loadSubjectTeacherGroups() {
      this.subjects.isLoading = true;
      subjectTeacherRepository
        .getSubjectTeacherGroup(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.subjects.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.subjects.isLoading = false;
        });
    },
    loadLocals() {
      this.locals.isLoading = true;
      localsRepository
        .getAllLocals()
        .then(({ data }) => {
          this.locals.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.locals.isLoading = false;
        });
    },
    loadDays() {
      this.days.isLoading = true;
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.days.isLoading = false;
        });
    },
    loadClassTimeByDay() {
      if (this.schedule.teacher_group.subject_type == "Modular") {
        this.classTime.isLoading = true;
        dailyBlockDetailsRepository
          .getClassTimeTechnicalByDay(this.days.selectedDay.id)
          .then(({ data }) => {
            this.classTime.data = data.data;
          })
          .catch((error) => {
            this.fireToast({
              icon: "error",
              title: error.message,
            });
          })
          .finally(() => {
            this.classTime.isLoading = false;
          });
      } else if (this.schedule.teacher_group.subject_type == "Académica") {
        this.classTime.isLoading = true;
        dailyBlockDetailsRepository
          .getClassTimeAcademicByDay(this.days.selectedDay.id)
          .then(({ data }) => {
            this.classTime.data = data.data;
          })
          .catch((error) => {
            this.fireToast({
              icon: "error",
              title: error.message,
            });
          })
          .finally(() => {
            this.classTime.isLoading = false;
          });
      }
    },
    loadSectionGrade() {
      this.sections.isLoading = true;
      technicalGroupRepository
        .getTechnicalSections(this.schedule.teacher_group.teacher_groupable_id)
        .then(({ data }) => {
          this.sections.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        })
        .finally(() => {
          this.sections.isLoading = false;
        });
    },
    create(data) {
      this.isLoading = true;
      schedulesRepository
        .createSchedule(data)
        .then(({ data }) => {
          // Showing alert with response data
          this.response = data;
          this.showMdlResponse();
          // Retrieving data and hidding modal if operation has been completed
          this.$emit("reloadSchedule");
        })
        .catch((error) => {
          // Something happened in setting up the request and triggered an Error
          console.log(error);
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.$v.$reset();
          this.resetSchedule();
          this.scheduleArray = [];
          this.formattedDataToStore = [];
        });
    },
    formattedData() {
      if (this.scheduleArray.length > 0) {
        this.scheduleArray.forEach((element) => {
          this.formattedDataToStore.push({
            teacher_group_id: element.schedule.teacher_group.id,
            section_id: element.schedule?.section
              ? element.schedule.section?.id
              : null,
            start_date: element.schedule.start_date,
            id_user: this.currentUserPersonalInfo.id_user,
            end_date: element.schedule.end_date,
            local_id: element.schedule.local_id.id,
            daily_block_detail_id: element.classTime.daily_block_id,
            start_time: element.classTime.start_time,
            end_time: element.classTime.end_time,
            day_id: element.day.id,
            day_name: element.day.name,
            local_name: element.schedule.local_id.name,
            subject_name: element.schedule.teacher_group.name,
            grade_information: element.schedule.teacher_group.grade_information,
          });
        });
        this.create(this.formattedDataToStore);
      } else {
        this.fireToast({
          icon: "error",
          title: "No se ha agregado nigún horario a la lista",
        });
      }
    },
    addScheduleToList() {
      if (this.schedule.teacher_group?.subject_type == "Académica") {
        this.$v.days.selectedDay.$touch();
        this.$v.classTime.selectDailyBlock.$touch();
        this.$v.schedule.teacher_group.$touch();
        this.$v.schedule.start_date.$touch();
        this.$v.schedule.end_date.$touch();
        this.$v.schedule.local_id.$touch();
        if (
          this.$v.days.selectedDay.$invalid ||
          this.$v.classTime.selectDailyBlock.$invalid ||
          this.$v.schedule.teacher_group.$invalid ||
          this.$v.schedule.start_date.$invalid ||
          this.$v.schedule.end_date.$invalid ||
          this.$v.schedule.local_id.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.scheduleArray.push({
            day: this.days.selectedDay,
            classTime: this.classTime.selectDailyBlock,
            schedule: this.schedule,
          });
          this.resetSchedule();
          this.$v.$reset();
        }
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar todos los campos correctamente",
          });
          return;
        }
        this.scheduleArray.push({
          day: this.days.selectedDay,
          classTime: this.classTime.selectDailyBlock,
          schedule: this.schedule,
        });
        this.resetSchedule();
        this.$v.$reset();
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    showMdlConfirmClose() {
      this.$refs.mdlOnCloseConfirmation.toggleModal();
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.resetSchedule();
      this.scheduleArray = [];
    },

    resetSchedule() {
      this.schedule = {
        local_id: null,
        teacher_group: null,
        day_id: null,
        id_user: null,
        start_date: null,
        end_date: null,
      };
      this.days.selectedDay = null;
      this.classTime.selectDailyBlock = null;
    },
    showMdlResponse() {
      this.$refs.mdlResponse.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    verifySubjectType() {
      if (this.schedule.teacher_group?.subject_type == "Modular") {
        return true;
      }
    },
  },
  validations: {
    days: {
      selectedDay: { required },
    },
    classTime: {
      selectDailyBlock: { required },
    },
    schedule: {
      teacher_group: { required },
      section: { required },
      start_date: { required },
      end_date: { required },
      local_id: { required },
    },
  },
  watch: {
    "currentUserPersonalInfo.id_user"(newValue) {
      this.loadSubjectTeacherGroups();
    },
    "days.selectedDay": "loadClassTime",
    "schedule.teacher_group": "loadClassTime",
  },
};
</script>
<style>
.scheduleList::-webkit-scrollbar {
  width: 5px !important;
}
.scheduleList::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
.scheduleList::-webkit-scrollbar-thumb {
  background: #888 !important;
}
.scheduleList::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.scheduleList {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
  max-height: 275px !important;
  overflow-y: auto !important;
}
</style>
