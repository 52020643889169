<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Horarios
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aquí se podrán gestionar los horarios.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Asignar horario
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <div v-if="teacherSchedule.isLoadingTeacherSchedule">
          <v-sheet class="mx-auto mt-5 py-10 px-4" elevation="0">
            <p class="text-center font-weight-bold text-h6">
              Cargando horarios
            </p>
            <v-row class="mt-5">
              <v-col class="mx-auto" cols="12" md="6" lg="4" xl="3">
                <v-progress-linear
                  indeterminate
                  color="blue darken-2"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
        <v-card
          class="elevation-0"
          v-if="
            teacherSchedule.data.length > 0 &&
              !teacherSchedule.isLoadingTeacherSchedule
          "
        >
          <v-tabs v-model="activeTab" centered>
            <v-tab v-for="day in days.data" :key="day.id">
              {{ day.name }}
            </v-tab>

            <v-tab-item v-for="day in days.data" :key="day.id">
              <v-card flat>
                <v-card-text>
                  <v-list two-line class="pa-0 ma-0">
                    <v-list-item-group>
                      <template v-if="getScheduleByDay(day.id).length > 0">
                        <v-list-item
                          v-for="(schedule, index) in getScheduleByDay(day.id)"
                          :key="index"
                          class="my-2 py-0 border-lg rounded-lg"
                        >
                          <v-list-item-content
                            :key="schedule.idschedule"
                            class="ma-0 pa-0 py-4"
                          >
                            <v-list-item-title>
                              <v-chip
                                small
                                :color="`${colors[index]} lighten-4`"
                                ><v-icon small :color="`${colors[index]}`"
                                  >mdi-account-group-outline</v-icon
                                ></v-chip
                              >
                              <span class="font-weight-bold ml-1"
                                >{{ schedule.grade }} -
                                {{
                                  !!schedule.speciality
                                    ? schedule.speciality
                                    : schedule.section
                                }}
                                {{ schedule.group }}
                                {{ schedule.technicalSection?.section }}</span
                              >
                            </v-list-item-title>
                            <v-list-item-title class="text-body-1"
                              ><v-chip
                                small
                                :color="`${colors[index]} lighten-4`"
                                ><v-icon small :color="`${colors[index]}`"
                                  >mdi-book-outline</v-icon
                                ></v-chip
                              >
                              <span class="font-weight-bold ml-1">{{
                                schedule.subject
                              }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-body-1"
                              ><v-chip
                                small
                                :color="`${colors[index]} lighten-4`"
                                ><v-icon small :color="`${colors[index]}`"
                                  >mdi-calendar-clock-outline</v-icon
                                ></v-chip
                              ><span class="ml-1"
                                >{{ schedule.day.name }}
                                {{ schedule.block }}</span
                              >
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-body-1"
                              ><v-chip
                                small
                                :color="`${colors[index]} lighten-4`"
                                ><v-icon small :color="`${colors[index]}`"
                                  >mdi-calendar-month-outline</v-icon
                                ></v-chip
                              ><span class="ml-1"
                                >Desde
                                <span class="font-weight-bold">{{
                                  schedule.start_date
                                }}</span>
                                Hasta
                                <span class="font-weight-bold">{{
                                  schedule.end_date
                                }}</span></span
                              >
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-body-1">
                              <v-chip
                                small
                                :color="`${colors[index]} lighten-4`"
                                ><v-icon small :color="`${colors[index]}`"
                                  >mdi-map-marker-outline</v-icon
                                ></v-chip
                              ><span class="ml-1">{{
                                schedule.local?.name
                              }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <div
                              class="d-flex flex-column justify-space-between"
                            >
                              <template v-if="true">
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="elevation-0 mb-2"
                                      fab
                                      dark
                                      x-small
                                      color="blue"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        selectSchedule(schedule);
                                        openDeferredAttendanceForm();
                                      "
                                    >
                                      <v-icon class="ma-3"
                                        >mdi-clock-outline</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Registro de asistencia diferida</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  existInArray('Editar', currentPageActions)
                                "
                              >
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="elevation-0 mb-2"
                                      fab
                                      dark
                                      x-small
                                      color="orange"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        selectSchedule(schedule);
                                        showMdlUpdate();
                                      "
                                    >
                                      <v-icon class="ma-3"
                                        >mdi-pencil-outline</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Editar horario</span>
                                </v-tooltip>
                              </template>
                              <template
                                v-if="
                                  existInArray('Eliminar', currentPageActions)
                                "
                              >
                                <v-tooltip left>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="elevation-0 mb-2"
                                      fab
                                      dark
                                      x-small
                                      color="red"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="
                                        selectSchedule(schedule);
                                        confirmDeleteModal();
                                      "
                                    >
                                      <v-icon class="ma-3"
                                        >mdi-delete-outline</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Eliminar horario</span>
                                </v-tooltip>
                              </template>
                            </div>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >No tiene horarios este día</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
        <v-card
          v-if="
            !(teacherSchedule.data.length > 0) &&
              !teacherSchedule.isLoadingTeacherSchedule
          "
          class="elevation-0"
          outlined
        >
          <v-row class="mt-5">
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10" md="8">
              <div class="mx-auto mb-4 pa-8 rounded-circle blue lighten-4">
                <v-icon x-large color="info">mdi-information-outline</v-icon>
              </div>
              <p class="text-center font-weight-medium text-h6 ma-2 mb-4">
                No se han encontrado horarios.
              </p>
            </v-col>
          </v-row>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <DeferredAttendanceRequestFormVue
      ref="MdlDeferredAttendanceForm"
      :selectedSchedule="selectedSchedule"
    ></DeferredAttendanceRequestFormVue>
    <CreateScheduleModal
      ref="mdlCreate"
      @reloadSchedule="getScheduleTeacher"
    ></CreateScheduleModal>
    <UpdateScheduleModal
      ref="mdlUpdate"
      :scheduleData="selectedSchedule"
      @reloadSchedule="getScheduleTeacher"
    ></UpdateScheduleModal>
  </div>
</template>

<script>
import CreateScheduleModal from "@/components/modals/schedules/CreateScheduleModal";
import UpdateScheduleModal from "../../../components/modals/schedules/UpdateScheduleModal.vue";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import daysRepository from "../../../repositories/daysRepository";
import schedulesRepository from "../../../repositories/schedulesRepository";
import Swal from "sweetalert2";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import DeferredAttendanceRequestFormVue from "@/components/modals/attendance/DeferredAttendanceRequestForm.vue";

export default {
  name: "schedules",
  title: "Horarios | GE ITR",
  components: {
    CreateScheduleModal,
    ChangeStatusBaseModal,
    UpdateScheduleModal,
    DeferredAttendanceRequestFormVue,
  },
  data() {
    return {
      activeTab: null,
      schedule: {
        headers: [
          { text: "Local", value: "name" },
          { text: "Bloque", value: "daily_block" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
      },
      teacherSchedule: {
        data: [],
        isLoadingTeacherSchedule: false,
      },
      days: {},
      selectedSchedule: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Horarios", route: "schedules" },
    ]);
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Horarios");
    this.getDays();
    if (this.currentUserPersonalInfo.id_user != null) {
      this.getScheduleTeacher();
    }
  },

  watch: {
    "currentUserPersonalInfo.id_user"(newValue) {
      this.getScheduleTeacher();
    },
  },

  methods: {
    openDeferredAttendanceForm() {
      this.$refs.MdlDeferredAttendanceForm.toggleModal();
    },
    selectSchedule(obj) {
      this.selectedSchedule = { ...obj };
    },
    unSelectSchedule() {
      this.selectedSchedule = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    getActualDay() {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
      const currentDay = this.days.data.find(
        (day) => day.id === adjustedDayOfWeek
      );
      this.activeTab = currentDay.id - 1;
    },
    getDays() {
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days = data;
          this.getActualDay();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al obtener los datos desde el servidor",
          });
        });
    },
    getScheduleTeacher() {
      this.teacherSchedule.isLoadingTeacherSchedule = true;
      schedulesRepository
        .getTeacherSchedule(this.currentUserPersonalInfo.id_user)
        .then(({ data }) => {
          this.teacherSchedule.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.teacherSchedule.isLoadingTeacherSchedule = false;
        });
    },
    getScheduleByDay(dayId) {
      return this.teacherSchedule.data.filter(
        (schedule) => schedule.day?.id === dayId
      );
    },
    confirmDeleteModal() {
      Swal.fire({
        title: "¿Desea eliminar el horario?",
        text: "Esta acción no se puede revertir.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSchedule();
        }
      });
    },
    deleteSchedule() {
      schedulesRepository
        .deleteSchedule(
          this.selectedSchedule.idschedule,
          this.selectedSchedule.teacher_groups_date
        )
        .then(({ data }) => {
          this.sweetAlertResponse(data);
          if (data.status) {
            const filteredData = this.teacherSchedule.data.filter(
              (item) => item.idschedule !== this.selectedSchedule.idschedule
            );
            this.teacherSchedule.data = filteredData;
          }
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
