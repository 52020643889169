import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "daily-block-details";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllDailyBlock = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getDailyBlock = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getDailyBlockByDay = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}/day-id`, id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getClassTimeAcademicByDay = (id) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/class-times/academic/day-id`,
    id
  );
};
export const getClassTimeTechnicalByDay = (id) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/class-times/technical/day-id`,
    id
  );
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const createDailyBlock = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

/**
 * PUT request to update a specific duration type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateDailyBlock = (id, payload) => {
  return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload);
};

/**
 * DELETE request to delete the specified duration type
 * @param id
 * @returns {*}
 */
export const deleteDailyBlock = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
  getAllDailyBlock,
  getDailyBlock,
  createDailyBlock,
  updateDailyBlock,
  deleteDailyBlock,
  getDailyBlockByDay,
  getClassTimeAcademicByDay,
  getClassTimeTechnicalByDay,
};
